import { PhotoMetadata } from "./template/Gallery";

const vertical = "3/4";
const horizontal = "3/2";

export const FSDPMetadata: PhotoMetadata[] = [
  { aspectRatio: horizontal },
];

export const StorefrontMetadata: PhotoMetadata[] = [
  { aspectRatio: horizontal },
];
export const SplitMetadata: PhotoMetadata[] = [
    { aspectRatio: horizontal },
];
