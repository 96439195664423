import React, { useState } from 'react';
import { useLocation, useRoutes, BrowserRouter, Route, Routes, HashHistory, Link} from 'react-router-dom';
import { createTheme, ThemeProvider, CssBaseline, Button, Switch } from '@mui/material';
import {Grid, Item} from '@mui/material';
import Navbar from './components/Navbar';
import NavMenu from './components/NavMenu';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Projects from './components/pages/Projects';
import ProjectsMobile from './components/pages/ProjectsMobile';
import ProjectDetails from './components/pages/ProjectDetails';
import Cursor from './components/Cursor';
import Skills from './components/Skills';
import Contact from './components/Contact';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { motion } from "framer-motion";
import {Gallery} from './components/Gallery.tsx';
import { FSDPMetadata, StorefrontMetadata, SplitMetadata } from "./data.ts";
import './App.css';
const App = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [checked, setChecked] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  var textColor = darkMode.darkMode? "black": "white";
  // Toggle dark mode
  const toggleDarkMode = () => {
    setDarkMode(darkMode);
  };
  // Create MUI theme
  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#2196f3',
      },
    },
  });
  // const element = useRoutes([
  //   {
  //     path: "/",
  //     element: <Home darkMode={darkMode}/>,
  //   },
  //   {
  //     path: "/Projects",
  //     element: <Projects/>
  //   },
  //   {
  //     path: "/About",
  //     element: <About/>
  //   },
  // ]);
  // const location = useLocation();
  // if (!element) {
  //   return null;
  // }
  const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (i) => {
      const delay = 1 + i * 0.5;
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
          opacity: { delay, duration: 0.01 }
        }
      };
    }
  };
  const handleChange = () => {
    if(checked){
      setChecked(false);
      setDarkMode(false);
    }else{
      setChecked(true);
      setDarkMode(true);
    }
  };
  const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30
  };


  return (
      <div className="gradBg">
          <Routes>
            <Route exact path="/" element={<Home/>}>
            </Route>
            <Route exact path="/About/" element={<About/>}>
            </Route>
            <Route exact path="/Projects/" element={width > 600? <Projects/> : <ProjectsMobile/>}>
            </Route>
            <Route exact path="/Projects/:projectId" element={<ProjectDetails/>}>
            </Route>
          </Routes>
      {/* <motion.div
        initial={{
          x: width * .05,
          y: height * .05,
          border: ".5px solid white",
          opacity: 0,
          height: height * .9,
          width: width * .9,
        }}
        animate={{
          x: width * .05,
          y: height * .05,
          backgroundColor: darkMode? "black": "white",
          position: "absolute",
          padding: "5%",
          border: ".5px solid",
          borderColor: darkMode? "white" : "black",
          opacity: 1,
          height: height * .9,
          width: width * .9,
          pathLength: 1,
        }}
        transition={{duration: 3}}
      > */}
        {/* <div className="switch" style={{backgroundColor: darkMode? "white" : "black"}} data-isOn={checked} onClick={handleChange}>
          <motion.div className="handle" style={{backgroundColor: darkMode? "black" : "white"}} layout transition={spring} />
        </div> */}
      {/* </motion.div> */}
      {width > 600? (<Navbar/>) : (<NavMenu/>)}
      <Link to="/Projects/" style={{display: "none"}}/>
      <Link to="/About/" style={{display: "none"}}/>
      {/* <Cursor/> */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
      </ThemeProvider>
    </div>
  );
};

export default App;
