import {React, useState, useEffect} from 'react';
import { motion } from 'framer-motion';
import { Typography, Button, Grid, Item, Image } from '@mui/material';
import Box from '@mui/material/Box';
const About = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
    return(
      <Box
      sx={{ display: 'flex', height: "65%" }}
      style={{textAlign: "left", fontFamily: "Space Mono, monospace", marginBottom: "10%"}}
      >
        <Grid container>
          <Grid xl={1} xs={1}></Grid>
          <Grid xl={1} xs={1}>
          <Typography variant="h1" style={{width: "1em", fontSize: "40px", letterSpacing: "40px", marginRight: "10%"}} className="projectText">A B O U T</Typography>
          </Grid>
          <Grid xl={.1} lg={.1} md={.1} xs={1}></Grid>
          <Grid xl={4} lg={4} md={4} xs={8} sx={{overflowY: 'scroll', maxHeight: width < 600? "550px":"450px", marginTop: width < 600? "15%": "5%"}}>
            <div>My name is Mikeo Skinner. I am a full stack developer in the .NET framework. I attended JCCC and KU for 3 years, majoring in computer science. Over the last year I've been sharpening my coding skills by doing freelance and coding personal projects. I currently work as a Software Developer at Barkley, where I'm learning how software development can impact the world of advertising.</div>
            <Typography variant="h4" style={{marginTop: "20%"}}>EXPERIENCE</Typography>
            <Typography variant="h5" style={{marginTop: "10%"}}>BARKLEY</Typography>
            <Typography variant="h7" style={{marginTop: "5%"}}>SEP 2022 - PRESENT</Typography>
            <Typography variant="h5" style={{marginTop: "2%"}}>SOFTWARE DEVELOPER</Typography>
            <Typography variant="h7" style={{marginTop: "1%"}}>Collaborated with the Data Engineering team to create unique data models and present them with UI/UX. </Typography>
            <Typography variant="h7" style={{marginTop: "1%"}}>Rebuilt automated trafficking tool to a data driven full stack application that increased efficiency in our trafficking process by 80% and improved accuracy by 50%. </Typography>
            <Typography variant="h7" style={{marginTop: "1%"}}>Coordinated with multiple teams to discover business needs for new applications. </Typography>
            <Typography variant="h7" style={{marginTop: "1%"}}>Leveraged advanced expertise in Google Tag Manager to implement effective trafficking strategies for a diverse range of clients, enabling optimized tracking and monitoring of website analytics for maximum ROI. </Typography>
            <Typography variant="h7" style={{marginTop: "1%"}}>Recreated an in house natural language processing tool that employees can use to train, using real client data and strategies to enhance all departments.</Typography>
            <Typography variant="h5" style={{marginTop: "10%"}}>WEB CONNECT</Typography>
            <Typography variant="h7" style={{marginTop: "5%"}}>FEB 2022 - SEP 2022</Typography>
            <Typography variant="h5" style={{marginTop: "2%"}}>SOFTWARE DEVELOPER</Typography>
            <Typography variant="h7" style={{marginTop: "1%"}}>Designed and constructed an intuitive and robust native community application for the company from scratch, utilizing React Native to deliver seamless user experiences. </Typography>
            <Typography variant="h7" style={{marginTop: "1%"}}>Revamped and modernized the company's legacy application by migrating to newer libraries, resulting in improved performance and streamlined workflows. </Typography>
            <Typography variant="h7" style={{marginTop: "1%"}}>Developed multiple full-stack business applications using  React and Redux, delivering responsive and dynamic user interfaces that enhance user engagement and productivity. </Typography>
            <Typography variant="h5" style={{marginTop: "10%"}}>THE EXOTIC UMBRELLA</Typography>
            <Typography variant="h7" style={{marginTop: "5%"}}>OCT 2021 - FEB 2022</Typography>
            <Typography variant="h5" style={{marginTop: "2%"}}>FREELANCE WEB DEVELOPER</Typography>
            <Typography variant="h7" style={{marginTop: "1%"}}>Coordinated with client to finalize design and confirm requirements. </Typography>
            <Typography variant="h7" style={{marginTop: "1%"}}>Designed company website including graphical user interface design and coding. </Typography>
            <Typography variant="h7" style={{marginTop: "1%"}}>Used React JS to develop web pages according to client specifications. </Typography>
            <Typography variant="h7" style={{marginTop: "1%"}}>Provided marketing support such as social media branding and image. </Typography>
            <Typography variant="h4" style={{marginTop: "20%"}}>EDUCATION</Typography>
            <Typography variant="h5" style={{marginTop: "10%"}}>CENTRIQ TRAINING FULL-STACK WEB DEVELOPER PROGRAM</Typography>
            <Typography variant="h7" style={{marginTop: "5%"}}>MAR 2020 - JUL 2020</Typography>
            <Typography variant="h5" style={{marginTop: "10%"}}>UNIVERSITY OF KANSAS</Typography>
            <Typography variant="h5" style={{marginTop: "5%"}}>COMPUTER SCIENCE</Typography>
            <Typography variant="h7" style={{marginTop: "5%"}}>AUG 2018 - MAY 2019</Typography>
            <Typography variant="h5" style={{marginTop: "10%"}}>JOHNSON COUNTY COMMUNITY COLLEGE</Typography>
            <Typography variant="h5" style={{marginTop: "5%"}}>COMPUTER SCIENCE</Typography>
            <Typography variant="h7" style={{marginTop: "5%"}}>JAN 2015 - MAY 2018</Typography>
          </Grid>
          {width > 900? (<Grid xl={5.9} lg={5.9} md={5.9} xs={0} style={{textAlign: "right"}}><img src={`/img/Portrait.png`} style={{height: "100%", width: "80%"}}></img></Grid>) : (<div></div>)}
        </Grid>
      </Box>
    )
  }

export default About;