import React, {useState} from 'react';
import { motion } from 'framer-motion';
import { Typography, Button, Link, Grid } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import ReactPlayer from 'react-player';
import SlidingText from '../SlidingText';
import Marquee from "react-fast-marquee";
const Home = (darkMode) => {
  var textColor = darkMode.darkMode? "black": "white";
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const src = "https://youtu.be/hLJN6OLQJeo";
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    p: 4,
  };
  return (
    <Grid container spacing={2} style={{marginTop: width < 600? "20%": "-6%"}}>
      <marquee width="100%" direction="right" height={width<600? "300px":"400px"} scrollamount="40" style={{fontFamily: "Anton, sans-serif", fontSize: width<600? "100px":"250px", animation: "marquee 5s linear infinite"}}>
        <span style={{letterSpacing: width<600? "5px":"20px"}}>MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER MIKEO SKINNER</span>
      </marquee>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <iframe width={width * .7} height={height * .7} src="https://www.youtube.com/embed/hLJN6OLQJeo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </Box>
      </Modal>
      <Grid container spacing={2} style={{marginTop: "11%"}}>
        <Grid item xl={6.7} lg={6.6} xs={12} style={{fontSize: "35px", fontFamily: 'Space Mono, monospace', textAlign: width<1200? "center":"right"}}>SOFTWARE ENGINEER &</Grid>
        <Grid item xl={5.3} lg={5.4} xs={12} style={{cursor: "pointer", fontSize: "35px", fontFamily: 'Space Mono, monospace', textAlign: width<1200? "center":"left"}} className="gradText" onClick={handleOpen}>BREAKDANCER</Grid>
      </Grid>

    </Grid>
  );
};

export default Home;
