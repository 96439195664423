import React, {useState} from 'react'
import { Cross as Hamburger } from 'hamburger-react'
import { Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
function NavMenu(){
    const [isOpen, setOpen] = useState(false)
  return (
    <>
    <Hamburger toggled={isOpen} toggle={setOpen} />
    {isOpen? (<div className="styledMenu">
        <Hamburger toggled={isOpen} toggle={setOpen}/>
        <Divider/>
        <a href="/" className="navLink">HOME</a>
        <a href="/Projects" className="navLink">PROJECTS</a>
        <a href="/About" className="navLink">ABOUT</a>
        <a href="https://github.com/pendemic" className="navLink">GITHUB</a>
        <a href="mailto:mikeokskinner@outlook.com" className="navLink">CONTACT</a>
    </div>) : (<div></div>)}
    </>
  )
}

export default NavMenu