// src/components/Cursor.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CursorWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 9999;
`;

const CircularText = styled.span`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  font-size: 12px;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  animation: spin 2s linear infinite;
  display: inline-block;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const Cursor = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [textRadius, setTextRadius] = useState(50); // Adjust this value to control the radius of the circle.

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const renderCircularText = () => {
    const text = 'Spinning Text';
    const characters = text.split('');

    return characters.map((char, index) => {
      // Calculate the angle for each character in the circle.
      const angle = (index / characters.length) * 360;
      // Convert the angle to radians for positioning.
      const angleInRadians = (angle * Math.PI) / 180;

      // Calculate the position of the character based on the radius.
      const charX = mousePosition.x + textRadius * Math.cos(angleInRadians);
      const charY = mousePosition.y + textRadius * Math.sin(angleInRadians);

      return (
        <CircularText key={index} top={charY} left={charX}>
          {char}
        </CircularText>
      );
    });
  };

  return <CursorWrapper>{renderCircularText()}</CursorWrapper>;
};

export default Cursor;
