import React, {useState} from 'react';
import {Grid, Item} from '@mui/material';
import { motion } from "framer-motion";
const Navbar = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [checked, setChecked] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  var textColor = darkMode.darkMode? "black": "white";
  return (
    <Grid container spacing={2} style={{marginTop: width < 400? "20%" : "1%", textAlign: "center", justifyContent: "center", fontFamily: "Space Mono, monospace"}}>
    <Grid item xs={"20%"}>
      <motion.div
        initial={{ opacity: 0, color: textColor }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.5, duration: 3 }}
        drag
        dragConstraints={{
          top: -375,
          left: -100,
          right: 1350,
          bottom: 325,
        }}
      >
        <motion.a initial={{ opacity: .5, color: textColor }} whileHover={{opacity: 1}} href="/" style={{textDecoration: "none", color: textColor, fontSize: 25}}>HOME</motion.a>
      </motion.div>
    </Grid>
    <Grid item xs={"20%"}>
      <motion.div
        initial={{ opacity: 0, color: textColor }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.5, duration: 3 }}
        drag
        dragConstraints={{
          top: -375,
          left: -100,
          right: 1350,
          bottom: 325,
        }}
      >
        <motion.a initial={{ opacity: .5, color: textColor }} whileHover={{opacity: 1}} style={{textDecoration: "none", color: textColor, fontSize: 25}}>|</motion.a>
      </motion.div>
    </Grid>
    <Grid item xs={"20%"}>
      <motion.div
        initial={{ opacity: 0, color: textColor }}
        animate={{ opacity: 1 }}
        transition={{ delay: 2, duration: 3 }}
        drag
        dragConstraints={{
          top: -375,
          left: -100,
          right: 1350,
          bottom: 325,
        }}
      >
        <motion.a initial={{ opacity: .5, color: textColor }} whileHover={{opacity: 1}} href="/About" style={{textDecoration: "none", color: textColor, fontSize: 25}}>ABOUT</motion.a>
      </motion.div>
    </Grid>
    <Grid item xs={"20%"}>
      <motion.div
        initial={{ opacity: 0, color: textColor }}
        animate={{ opacity: 1 }}
        transition={{ delay: 2, duration: 3 }}
        drag
        dragConstraints={{
          top: -375,
          left: -100,
          right: 1350,
          bottom: 325,
        }}
      >
        <motion.a initial={{ opacity: .5, color: textColor }} whileHover={{opacity: 1}} style={{textDecoration: "none", color: textColor, fontSize: 25}}>|</motion.a>
      </motion.div>
    </Grid>
    <Grid item xs={"20%"}>
      <motion.div
        initial={{ opacity: 0, color: textColor }}
        animate={{ opacity: 1 }}
        transition={{ delay: 2.5, duration: 3 }}
        drag
        dragConstraints={{
          top: -425,
          left: -100,
          right: 1350,
          bottom: 275,
        }}
      >
        <motion.a initial={{ opacity: .5, color: textColor }} whileHover={{opacity: 1}} href="/Projects" style={{textDecoration: "none", color: textColor, fontSize: 25}}>PROJECTS</motion.a>
      </motion.div>
    </Grid>
    <Grid item xs={"20%"}>
      <motion.div
        initial={{ opacity: 0, color: textColor }}
        animate={{ opacity: 1 }}
        transition={{ delay: 2.5, duration: 3 }}
        drag
        dragConstraints={{
          top: -375,
          left: -100,
          right: 1350,
          bottom: 325,
        }}
      >
        <motion.a initial={{ opacity: .5, color: textColor }} whileHover={{opacity: 1}} style={{textDecoration: "none", color: textColor, fontSize: 25}}>|</motion.a>
      </motion.div>
    </Grid>
    <Grid item xs={"20%"}>
      <motion.div
        initial={{ opacity: 0, color: textColor }}
        animate={{ opacity: 1 }}
        transition={{ delay: 3, duration: 3 }}
        drag
        dragConstraints={{
          top: -445,
          left: -100,
          right: 1350,
          bottom: 255,
        }}
      >
        <motion.a initial={{ opacity: .5, color: textColor }} whileHover={{opacity: 1}} href="https://github.com/pendemic" target="_blank" style={{textDecoration: "none", color: textColor, fontSize: 25}}>GITHUB</motion.a>
      </motion.div>
    </Grid>
    <Grid item xs={"20%"}>
      <motion.div
        initial={{ opacity: 0, color: textColor }}
        animate={{ opacity: 1 }}
        transition={{ delay: 3, duration: 3 }}
        drag
        dragConstraints={{
          top: -375,
          left: -100,
          right: 1350,
          bottom: 325,
        }}
      >
        <motion.a initial={{ opacity: .5, color: textColor }} whileHover={{opacity: 1}} style={{textDecoration: "none", color: textColor, fontSize: 25}}>|</motion.a>
      </motion.div>
    </Grid>
    <Grid item xs={"20%"}>
      <motion.div
        initial={{ opacity: 0, color: textColor }}
        animate={{ opacity: 1 }}
        transition={{ delay: 3.5, duration: 3 }}
        drag
        dragConstraints={{
          top: -445,
          left: -100,
          right: 1350,
          bottom: 255,
        }}
      >
        <motion.a initial={{ opacity: .5, color: textColor }} whileHover={{opacity: 1}} href="mailto:mikeokskinner@outlook.com" style={{textDecoration: "none", color: textColor, fontSize: 25}}>CONTACT</motion.a>
      </motion.div>
    </Grid>
  </Grid>
  );
}

export default Navbar;
