import {useState, React} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import {Grid, Item} from '@mui/material';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { motion } from 'framer-motion';
import { Image } from "../Image.tsx";
import Modal from '@mui/material/Modal';

function TabPanel(props, darkMode) {
  const { children, value, index, ...other } = props;
  var textColor = darkMode.darkMode? "white": "black";
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ paddingLeft: 10 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs(darkMode) {
  const [value, setValue] = useState(0);
  var textColor = darkMode.darkMode? "white": "black";
  var bgColor = darkMode.darkMode? "black": "white";
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width * .9,
    bgcolor: 'transparent',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [selectedPost, setSelectedPost] = useState(null);
  const renderModal = () =>{
    if (selectedPost !== null){
      const category = selectedPost;
      if(category == "SPLIT-1" || category == "SPLIT-2"){
        return(
        <Box sx={style} style={{textAlign: "center"}}>
          <img src={`/img/${category}.png`} style={{height: "100%", width: "30%"}}></img>
        </Box>
        )
      }else{
        return(
        <Box sx={style} style={{textAlign: "center"}}>
          <img src={`/img/${category}.png`} style={{height: "100%", width: "100%"}}></img>
        </Box>
        )
      }
    }else{
      return null;
    }
  }
  const setSelect = (category) =>{
    setSelectedPost(category);
    handleOpen();
  }

  return (
    <Box
      sx={{ flexGrow: 1, display: 'flex', height: "65%", marginLeft: "auto", marginBottom: "10%" }}
      style={{backgroundColor: "transparent"}}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{renderModal()}</div>
      </Modal>
      <Typography variant="h1" style={{width: "1em", fontSize: "40px", letterSpacing: "40px",marginLeft: "10%"}} className="projectText">P R O J E C T S</Typography>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: textColor, width: '100%', justifyContent: "left", overflowY: "visible", overflowX: "visible"}}
      >
        <Tab label="BreakTime" {...a11yProps(0)} style={{color: "white", fontFamily: "Space Mono, monospace"}} />
        <Tab label="Cactus Club" {...a11yProps(1)} style={{color: "white", fontFamily: "Space Mono, monospace"}} />
        <Tab label="Smartender" {...a11yProps(2)} style={{color: "white", fontFamily: "Space Mono, monospace"}}/>
        <Tab label="Split" {...a11yProps(3)} style={{color: "white", fontFamily: "Space Mono, monospace"}}/>
        <Tab label="Portfolio" {...a11yProps(4)} style={{color: "white", fontFamily: "Space Mono, monospace"}}/>
        <Tab label="Class Chat Bot" {...a11yProps(5)} style={{color: "white", fontFamily: "Space Mono, monospace"}}/>
        <Tab label="Michelin Star Bets" {...a11yProps(6)} style={{color: "white", fontFamily: "Space Mono, monospace"}}/>
      </Tabs>
      <TabPanel value={value} index={0}>
        <Grid container spacing={2} sx={{overflowY: 'scroll', maxHeight: '500px', width: '90%'}}>
        <Grid item xs={10} onClick={() => setSelect("FSDP Project")}>
          <Image
            category={"FSDP Project"}
            index={0}
            alt={"alt"}
          />
        </Grid>
        <Grid item xs={10} onClick={() => setSelect("FSDP-2")}>
          <Image
            category={"FSDP-2"}
            index={0}
            alt={"alt"}
          />
        </Grid>
        <Grid item xs={10} onClick={() => setSelect("FSDP-3")}>
          <Image
            category={"FSDP-3"}
            index={0}
            alt={"alt"}
          />
        </Grid>
        <Grid item xs={10} style={{fontFamily: "Space Mono, monospace"}}>
        An application that allows users to make accounts for members that they want to register for reservations. Users are be able to register their members to any location however they can not be duplicated. Each class must also have a limit that cannot be exceeded by the user but can be exceeded by the administrator.
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Stack used: .NET
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Front End - HTML, CSS, JS, BootStrap
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Middle - C#, LINQ, EF, MVC
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Back End - SQL, SQL Server
        </Grid>
      </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Grid container spacing={2} sx={{overflowY: 'scroll', maxHeight: '500px', width: '90%'}}>
        <Grid item xs={10} onClick={() => setSelect("Storefront")}>
            <Image
              category={"Storefront"}
              index={0}
              alt={"alt"}
            />
          </Grid>
          <Grid item xs={10} onClick={() => setSelect("Storefront-2")}>
          <Image
            category={"Storefront-2"}
            index={0}
            alt={"alt"}
          />
        </Grid>
          <Grid item xs={10} style={{fontFamily: "Space Mono, monospace"}}>
          An application for managing product data. Application is built to simulate an online store front with a shopping cart. Administrators have the ability to add new products. This was built fully in react with the db hosted on my JSON Server.
          </Grid>
          <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Stack used:
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Front End - ReactJS, BootStrap, BulmaCSS
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Back End - NodeJS, JSON Web Server, Express
        </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <Grid container spacing={2} sx={{overflowY: 'scroll', maxHeight: '500px', width: '90%'}}>
        <Grid item xs={10} onClick={() => setSelect("Smartender-1")}>
          <Image
            category={"Smartender-1"}
            index={0}
            alt={"alt"}
          />
        </Grid>
        <Grid item xs={10} onClick={() => setSelect("Smartender-2")}>
          <Image
            category={"Smartender-2"}
            index={0}
            alt={"alt"}
          />
        </Grid>
        <Grid item xs={10} onClick={() => setSelect("Smartender-3")}>
          <Image
            category={"Smartender-3"}
            index={0}
            alt={"alt"}
          />
        </Grid>
        <Grid item xs={10} style={{fontFamily: "Space Mono, monospace"}}>
        A data-driven application that allows the user to view a list of cocktails based on the alcohol that you have available.
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Stack used: .NET
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Front End - HTML, CSS, JS, BootStrap
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Middle - C#, LINQ, EF, MVC
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Back End - SQL, SQL Server
        </Grid>
      </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <Grid container spacing={2} style={{height: '50%'}} sx={{overflowY: 'scroll', maxHeight: '500px', width: '90%'}}>
        <Grid item xs={10}>
          <img src={`/img/SPLIT-1.png`} style={{height: "100%", width: "30%", marginRight: "3%"}} onClick={() => setSelect("SPLIT-1")} />
          <img src={`/img/SPLIT-2.png`} style={{height: "100%", width: "30%"}} onClick={() => setSelect("SPLIT-2")}/>
        </Grid>
        <Grid item xs={8}>
          <div style={{fontFamily: "Space Mono, monospace"}}>An application built fully in React Native that will function as a bill splitter. The user will be able to add items and people and assign accordingly. This app will also have login and camera text recognition. Users will be able to create accounts, connect with their contacts, access past bills and send requests to their friends. This application is still in progress and I plan on releasing it to the app market later this year.</div>
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Stack used:
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Front End - React Native, MaterialUI
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Back End - NodeJS, SQL Server, Express
        </Grid>
      </Grid>
      </TabPanel>
      <TabPanel value={value} index={4}>
      <Grid container spacing={2} sx={{overflowY: 'scroll', maxHeight: '500px', width: '90%'}}>
        <Grid item xs={10} onClick={() => setSelect("YusraPortfolio")}>
          <Image
            category={"YusraPortfolio"}
            index={0}
            alt={"alt"}
          />
        </Grid>
        <Grid item xs={10} onClick={() => setSelect("YusraPortfolio-1")}>
          <Image
            category={"YusraPortfolio-1"}
            index={0}
            alt={"alt"}
          />
        </Grid>
        <Grid item xs={10} onClick={() => setSelect("YusraPortfolio-2")}>
          <Image
            category={"YusraPortfolio-2"}
            index={0}
            alt={"alt"}
          />
        </Grid>
        <Grid item xs={10} style={{fontFamily: "Space Mono, monospace"}}>
        This is a portfolio website I made for Yusra Nabi. Designs were created in Canva and brought to life using React and MaterialUI.
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Front End - ReactJS, MaterialUI, BootStrap, Framer Motion
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Back End - No Back End
        </Grid>
      </Grid>
      </TabPanel>
      <TabPanel value={value} index={5}>
      <Grid container spacing={2} sx={{overflowY: 'scroll', maxHeight: '500px', width: '90%'}}>
        <Grid item xs={10} onClick={() => setSelect("ChatBot")}>
          <Image
            category={"ChatBot"}
            index={0}
            alt={"alt"}
          />
        </Grid>
        <Grid item xs={10} style={{fontFamily: "Space Mono, monospace"}}>
        This is a chatbot that uses an LLM to answer questions about documents. You can load in as many PDFs as you want and it will parse through all of them. It will then tokenize your question and retrieve context about the question and give you sources.
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Front End - Chainlit
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Back End - Python
        </Grid>
      </Grid>
      </TabPanel>
      <TabPanel value={value} index={6}>
      <Grid container spacing={2} sx={{overflowY: 'scroll', maxHeight: '500px', width: '90%'}}>
        <Grid item xs={10} onClick={() => setSelect("NBA_output")}>
          <Image
            category={"NBA_output"}
            index={0}
            alt={"alt"}
          />
        </Grid>
        <Grid item xs={10} style={{fontFamily: "Space Mono, monospace"}}>
        This is an application that predicts NBA player lines. I used historical data to train a model to make predictions and comprare them to current betting lines in order to determine the best bets. It is currently just a console application but the next iteration will have a client side.
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Front End - TBD but most likely Streamlit
        </Grid>
        <Grid item xs={10} style={{fontWeight: 'bold', fontFamily: "Space Mono, monospace"}}>
          Back End - Python
        </Grid>
      </Grid>
      </TabPanel>
    </Box>
  );
}
