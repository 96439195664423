import React, {useState, useEffect} from 'react'
import {Grid, Item} from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';
function ProjectDetails() {
    let {projectId} = useParams();
    const [height, setHeight] = useState(window.innerHeight);
    const [projectName, setProjectName] = useState("");
    const [projectImg1, setProjectImg1] = useState("");
    const [projectImg2, setProjectImg2] = useState("");
    const [projectImg3, setProjectImg3] = useState("");
    const [projectDesc, setProjectDesc] = useState("");
    const [projectStack1, setProjectStack1] = useState("");
    const [projectStack2, setProjectStack2] = useState("");
    useEffect(() => {
        switch(projectId){
            case "1":
                setProjectName("Break Time");
                setProjectImg1("../../img/FSDP Project.PNG");
                setProjectImg2("../../img/FSDP-2.PNG");
                setProjectImg3("../../img/FSDP-3.PNG");
                setProjectDesc("An application that allows users to make accounts for members that they want to register for reservations. Users are be able to register their members to any location however they can not be duplicated. Each class must also have a limit that cannot be exceeded by the user but can be exceeded by the administrator.")
                setProjectStack1("Front End - HTML, CSS, JS, BootStrap")
                setProjectStack2("Back End - SQL, SQL Server, C#, LINQ, EF, MVC")
                break;
            case "2":
                setProjectName("Cactus Club");
                setProjectImg1("../../img/Storefront.PNG");
                setProjectImg2("../../img/Storefront-2.PNG");
                setProjectDesc("An application for managing product data. Application is built to simulate an online store front with a shopping cart. Administrators have the ability to add new products. This was built fully in react with the db hosted on my JSON Server.")
                setProjectStack1("Front End - ReactJS, BootStrap, BulmaCSS")
                setProjectStack2("Back End - NodeJS, JSON Web Server, Express")
                break;
            case "3":
                setProjectName("Smartender");
                setProjectImg1("../../img/Smartender-1.png");
                setProjectImg2("../../img/Smartender-2.png");
                setProjectImg3("../../img/Smartender-3.png");
                setProjectDesc("A data-driven application that allows the user to view a list of cocktails based on the alcohol that you have available.")
                setProjectStack1("Front End - HTML, CSS, JS, BootStrap")
                setProjectStack2("Back End - SQL, SQL Server, C#, LINQ, EF, MVC")
                break;
            case "4":
                setProjectName("SPLIT");
                setProjectImg1("../../img/SPLIT.png");
                setProjectImg2("../../img/SPLIT-1.png");
                setProjectImg3("../../img/SPLIT-2.png");
                setProjectDesc("An application built fully in React Native that will function as a bill splitter. The user will be able to add items and people and assign accordingly. This app will also have login and camera text recognition. Users will be able to create accounts, connect with their contacts, access past bills and send requests to their friends. This application is still in progress and I plan on releasing it to the app market later this year.")
                setProjectStack1("Front End - React Native, MaterialUI")
                setProjectStack2("Back End - NodeJS, SQL Server, Express")
                break;
            case "5":
                setProjectName("Yusra Nabi Portfolio");
                setProjectImg1("../../img/YusraPortfolio.png");
                setProjectImg2("../../img/YusraPortfolio-1.png");
                setProjectImg3("../../img/YusraPortfolio-2.png");
                setProjectDesc("This is a portfolio website I made for Yusra Nabi. Designs were created in Canva and brought to life using React and MaterialUI.")
                setProjectStack1("Front End - ReactJS, MaterialUI, Framer Motion")
                setProjectStack2("Back End - No Backend")
                break;
            case "6":
                setProjectName("Class ChatBot");
                setProjectImg1("../../img/ChatBot.png");
                setProjectDesc("This is a chatbot that uses an LLM to answer questions about documents. You can load in as many PDFs as you want and it will parse through all of them. It will then tokenize your question and retrieve context about the question and give you sources.")
                setProjectStack1("Front End - Chainlit")
                setProjectStack2("Back End - Python")
                break;
            case "7":
                setProjectName("Michelin Star Bets");
                setProjectImg1("../../img/Nba_output.png");
                setProjectDesc("This is an application that predicts NBA player lines. I used historical data to train a model to make predictions and comprare them to current betting lines in order to determine the best bets. It is currently just a console application but the next iteration will have a client side.")
                setProjectStack1("Front End - TBD but most likely Streamlit")
                setProjectStack2("Back End - Python")
                break;
            default:
                break;
        }
    }, [])
    
  return (
    <>
    <Box
        sx={{
            width: '100%',
            height: '100%',
            overflowY: "scroll",
        }}
        style={{fontFamily: "Space Mono, monospace"}}
    >
        <Grid container spacing={2} style={{color: 'white', marginTop: "20%"}}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
                <strong>{projectName}</strong>
                <Divider style={{backgroundColor: "white"}}/>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
                <img src={projectImg1} width="100%"/>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
            <strong>Description:</strong>
            <Divider style={{backgroundColor: "white"}}/>
            <br/>
            {projectDesc}
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
                <strong>Stack used:</strong>
                <Divider style={{backgroundColor: "white"}}/>
                <br/>
                {projectStack1}
                <br/>
                {projectStack2}
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
                <img src={projectImg2} width="100%"/>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
                <img src={projectImg3} width="100%"/>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={6}>
                <Link to="/Projects" style={{color: "white", textDecoration: "none"}}>Back to projects</Link>
            </Grid>
        </Grid>
    </Box>
    </>
  )
}

export default ProjectDetails