import React, {useState} from 'react'
import {Grid, Item} from '@mui/material';
import Box from '@mui/material/Box';
import {Link} from 'react-router-dom';
function ProjectsMobile() {
    const [height, setHeight] = useState(window.innerHeight);
  return (
    <Box
    sx={{
        width: '100%',
        height: height,
        overflowY: "scroll",
    }}
    >
    <div className="mobileDivider"></div>
    <div className="mobileVertical"></div>
    <div variant="h1" style={{width: "1em", fontSize: "20px", letterSpacing: "40px",marginLeft: "10%"}} className="projectText">P R O J E C T S</div>
    <Grid spacing={3} container style={{marginTop: "18%", marginBottom: "150%"}}>
        <Grid item xs={3} style={{writingMode: "vertical-rl"}} className="projectText">Reservation System</Grid>
        <Grid item xs={8}>
            <Link to='/Projects/1'><img src="../../img/FSDP Project.PNG" width="100%"/></Link>
        </Grid>
        <Grid item xs={3} style={{writingMode: "vertical-rl"}} className="projectText">E-Commerce</Grid>
        <Grid item xs={8}>
            <Link to='/Projects/2'><img src="../../img/Storefront.PNG" width="100%"/></Link>
        </Grid>
        <Grid item xs={3} style={{writingMode: "vertical-rl"}} className="projectText">Drink Randomizer</Grid>
        <Grid item xs={8}>
            <Link to='/Projects/3'><img src="../../img/Smartender-1.png" width="100%"/></Link>
        </Grid>
        <Grid item xs={3}style={{writingMode: "vertical-rl"}} className="projectText">Bill Splitting</Grid>
        <Grid item xs={8}>
            <Link to='/Projects/4'><img src="../../img/SPLIT.png" width="100%"/></Link>
        </Grid>
        <Grid item xs={3}style={{writingMode: "vertical-rl"}} className="projectText">Portfolio</Grid>
        <Grid item xs={8}>
            <Link to='/Projects/5'><img src="../../img/YusraPortfolio.png" width="100%"/></Link>
        </Grid>
        <Grid item xs={3}style={{writingMode: "vertical-rl"}} className="projectText">Class ChatBot</Grid>
        <Grid item xs={8}>
            <Link to='/Projects/6'><img src="../../img/ChatBot.png" width="100%"/></Link>
        </Grid>
        <Grid item xs={3}style={{writingMode: "vertical-rl"}} className="projectText">Michelin Star Bets</Grid>
        <Grid item xs={8}>
            <Link to='/Projects/7'><img src="../../img/Nba_output.png" width="100%"/></Link>
        </Grid>
    </Grid>
    </Box>
  )
}

export default ProjectsMobile